//use for overriding prime ng default item styles
.p-button-primary {
  background: linear-gradient(to right, cssVar(btn-grad1) 0%, cssVar(btn-grad2) 100%) !important;
  transition: none;

  &:hover,
  &:active {
    background: linear-gradient(to right, cssVar(btn-grad2) 0%, cssVar(btn-grad1) 100%) !important;
    @extend %animateFast;
  }
}

.p-inputtext {
  background: cssVar(input-bg);
  color: cssVar(write);
  margin-top: 5px;
  border-radius: 5px !important;
  padding: 9px;
  border-color: cssVar(brdr);
}

.p-dropdown {
  background: cssVar(input-bg);
  color: cssVar(write);
  margin-top: 5px;
  border-radius: 5px !important;
  border-color: cssVar(brdr);

  .p-inputtext {
    margin-top: 0px;
  }
}

.p-multiselect {
  background: cssVar(input-bg);
  color: cssVar(write);
  margin-top: 5px;
  border-radius: 5px !important;
  border-color: cssVar(brdr);

  .p-multiselect-panel {
    background: cssVar(input-bg);
    color: cssVar(write);

    .p-multiselect-item {
      color: cssVar(write);
    }

    .p-multiselect-header {
      background: cssVar(header-bg);
      color: cssVar(write);
      color: cssVar(write);
    }

    &.modalityselection {
      min-width: 200px;
    }
  }
}

.p-autocomplete {
  margin-top: 5px;

  .p-inputtext {
    margin-top: 0px;
    border-radius: 5px 0px 0px 5px !important;
    border-right: 0px !important;

    &:hover,
    &:focus,
    &:active {
      outline: none;
      box-shadow: none;
    }
  }

  .p-autocomplete-dropdown {
    background-color: cssVar(mat-bg) !important;
    border-left: 0px !important;
    border-color: cssVar(brdr);
    color: cssVar(brdr);

    &:hover,
    &:focus,
    &:active {
      outline: none;
      box-shadow: none;
      border-color: cssVar(brdr);
      color: cssVar(brdr);
      background-color: transparent !important;
    }
  }

  .p-autocomplete-panel {
    background: cssVar(input-bg);
    color: cssVar(write);

    .p-autocomplete-item {
      background: cssVar(input-bg);
      color: cssVar(write);
    }
  }
}

.p-dropdown-panel {
  background: cssVar(input-bg);
  color: cssVar(write);

  .p-dropdown-items {
    .p-dropdown-item {
      color: cssVar(write);

      &.p-highlight {
        background: cssVar(header-bg);
        color: cssVar(write);
      }
    }
  }
}

.p-calendar {
  margin-top: 5px;

  .p-inputtext {
    margin-top: 0px;
    // border-radius: 5px 0px 0px 5px !important;
    border-right: 1px solid !important;
    border-color: cssVar(brdr) !important;

    &:hover,
    &:focus,
    &:active {
      outline: none;
      box-shadow: none;
    }
  }

  .p-autocomplete-panel {
    background: cssVar(input-bg);
    color: cssVar(write);

    .p-autocomplete-item {
      background: cssVar(input-bg);
      color: cssVar(write);
    }
  }
}

.text-danger {
  color: cssVar(danger) !important; //red
}

.text-success {
  color: rgb(11, 87, 4) !important; // green
}

.text-primery {
  color: cssVar(primery) !important; // blue
}

.text-warning {
  color: cssVar(warning) !important; // orange(yellow)
}

.text-info {
  color: cssVar(info) !important; //  Lite blue
}

.text-accent {
  color: rgb(8, 8, 160) !important; //   dark blue number noted
}

.text-default {
  // color: cssVar(default) !important; //  violet
  color: #c107c1 !important ;
}

.text-light {
  color: cssVar(light) !important; //  white
}

.active {
  .p-badge-danger {
    background-color: transparent !important;
    color: cssVar(danger) !important;
    cursor: pointer;
  }

  .p-badge-info {
    background-color: transparent !important;
    color: cssVar(accent) !important;
    cursor: pointer;
  }

  .p-badge-accent {
    background-color: transparent !important;
    color: cssVar(accent) !important;
    cursor: pointer;
  }

  .p-badge-warning {
    background-color: transparent !important;
    color: cssVar(warning) !important;
    cursor: pointer;
  }

  .p-badge-success {
    background-color: transparent !important;
    color: cssVar(success) !important;
    cursor: pointer;
  }

  .p-badge-default {
    background-color: transparent !important;
    color: cssVar(default) !important;
    cursor: pointer;
  }
}

.p-datepicker:not(.p-datepicker-inline) {
  background-image: radial-gradient(#262942, #06091e);
  border: 1px solid cssVar(brdr);

  .p-datepicker-header {
    color: cssVar(write);
    background: cssVar(header-bg);

    .p-datepicker-title {
      .p-datepicker-month {
        color: cssVar(write);
        font-weight: bold;
      }

      .p-datepicker-year {
        color: cssVar(write);
        font-weight: bold;
      }
    }
  }

  .p-button.p-button-text {
    background: cssVar(mat-bg-el);
  }
}

.p-datepicker {
  table {
    color: cssVar(write);
  }
}

.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
  background-image: radial-gradient(#262942, #06091e);
}

.p-autocomplete .p-button {
  margin-right: 0px !important;
}

.p-calendar .p-datepicker {
  @media screen and (max-width: 991px) {
    right: auto !important;
    left: 0 !important;
  }
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token input {
  color: #ffffff !important;
}
.rt {
  .p-calendar .p-datepicker {
    @media screen and (max-width: 991px) {
      right: 0 !important;
      left: auto !important;
      // width: 260px;
      margin-right: -45px;
    }
  }
}

.p-toast {
  width: 30%;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
  .p-toast-detail {
    margin-top: 0px !important;
  }
  .p-toast-message-content {
    align-items: center;
  }
}
.p-toast-top-right {
  @media screen and (max-width: 767px) {
    top: 10px !important;
    right: 0px !important;
    left: 0px !important;
  }
}
.inputclr {
  .p-calendar .p-inputtext {
    margin-top: 0px;
    border-right: 1px solid !important;
    border-right-color: currentcolor;
    border-color: #f0eff5 !important;
  }
}
.p-panel .p-panel-header {
  font-size: 17px;
  color: #ffffff;
  background: #222434;
  text-transform: uppercase;
  font-weight: 300;
  padding-left: 0.5rem;
  border: none;
}
.p-panel .p-panel-content {
  color: #ffffff;
  background: #222434;
  border: none;
  padding: 0rem;
  padding-left: 0.5rem;
}
.p-panel .p-panel-header .p-panel-title {
  font-weight: 600;
  padding-left: 0.5rem;
}
.p-panel-header-icon {
  color: #fff;
}
.p-panel-header-icon:enabled:hover {
  background: #222434;
}
.p-panel .p-panel-header {
  display: flex;

  align-items: center;
}

.p-panel .p-panel-header .p-panel-header-icon {
  order: 2; /* Ensures the icon is positioned at the end */
  color: white;
}

.p-panel .p-panel-header .p-panel-title {
  flex: 1; /* Ensures the title takes up available space */
}
.rm-mr {
  .p-splitbutton {
    .p-button {
      margin-right: 0rem !important;
    }
  }
}
