// 𝕿𝖍𝖊𝖒𝖊 𝕭𝖑𝖚𝖊
@use "sass:meta";

$font-family: "Lato", sans-serif;
$scfont-family: "Raleway", sans-serif;

$theme: (
  theme-name: "theme-dark",
  write: #fff,
  mat-bg: #181a2c,
  mat-bg-l: #222434,
  mat-bg-el: #252844,
  base-font-size: 14px,
  rad: 2px border-radius,
  font-family: $font-family,
  bg: rgba(#f00, 0.05),
  btn-grad1: #2699ce,
  btn-grad2: #0c6791,
  text: #ffffff,
  text-dialog: #fff,
  input-bg: #181a2c,
  link: #ebebeb,
  brdr: #72709c,
  color: #fff,
  login-text: #fff,
  login-bg: #003558,
  footer-text: #fff,
  footer-bg: #17192a,
  header-bg: #17192a,
  header-text: #ebebeb,
  header-link: #ebebeb,
  table-head: #cdd2db,
  border-color: #c8c7cc,
  border-color2: #bbc5d5,
  table-cl1: #8896ad,
  table-evn: #efeff8,
  sidebarText: #fff,
  edit-icon: #576d7e,
  del-icon: #eb3425,
  sidebar-bg: rgba(#004e81, 0.8),
  menu-border: #ebebf2,
  menubar: #a5a4bf,
  balanceClr: #13a15a,
  types: (
    dark: rgb(237, 13, 13),
    light: #fff,
    info: #0f8fe4,
    theme: #004e81,
    accent: #025a91,
    danger: #ff0015,
    success: #35f10f,
    warning: #ff7142,
    default: #ee82ee,
    primary: #004e81,
  ),
  contrast: (
    dark: #fff,
    info: #fff,
    accent: #fff,
    primary: #fff,
    success: #fff,
    warning: #fff,
    default: #000,
    danger: #fff,
    theme: #fff,
    light: #000,
  ),
);

$font-family: var(--app-font-family, meta.inspect(map-get($theme, font-family)));

$color: map-get($theme, types);
$cont: map-get($theme, contrast);

:root {
  @each $key, $value in $theme {
    @if (type-of($value) != "map") {
      --#{$cssVarPrefix}-#{$key}: #{$value};
    }
  }
  @each $key, $value in $color {
    @if (type-of($value) != "map") {
      --#{$cssVarPrefix}-#{$key}: #{$value};
    }
  }
}
