@use "sass:meta";
@use "sass:string";

$cssVarPrefix: "app";

$font-family: "Source Sans Pro", sans-serif;
// $scfont-family: "LetterGothicMTStd", monospace;

$icomoon-font-path: "~/assets/icons";

// $dir: ltr;
// $dir: rtl;

// COMMON FONT CHART
// -----------------
$fontUL: 100;
$fontT: 200;
$fontL: 300;
$font: 400;
$fontM: 500;
$fontSB: 600;
$fontB: 700;
$fontH: 800;
$fontBK: 900;

$theme: (
  color: #fff,
  write: #fff,
  text: #ffffff,
  mat-bg: #181a2c,
  mat-bg-l: #222434,
  mat-bg-el: #252844,
  base-font-size: 14px,
  rad: 2px border-radius,
  font-family: $font-family,
  bg: rgba(#f00, 0.05),
  btn-grad1: #2699ce,
  btn-grad2: #0c6791,
  text-dialog: #fff,
  input-bg: #181a2c,
  link: #ebebeb,
  brdr: #72709c,
  login-text: #fff,
  login-bg: #003558,
  footer-text: #fff,
  footer-bg: #17192a,
  header-bg: #17192a,
  header-text: #ebebeb,
  header-link: #ebebeb,
  table-head: #cdd2db,
  border-color: #c8c7cc,
  border-color2: #bbc5d5,
  table-cl1: #8896ad,
  table-evn: #efeff8,
  sidebarText: #fff,
  edit-icon: #576d7e,
  del-icon: #eb3425,
  sidebar-bg: rgba(#004e81, 0.8),
  menu-border: #ebebf2,
  menubar: #a5a4bf,
  balance-clr: #13a15a,
  types: (
    light: #fff,
    dark: #ed0d0d,
    info: #0f8fe4,
    theme: #004e81,
    accent: #025a91,
    danger: #ff0015,
    success: #35f10f,
    warning: #ff7142,
    default: #ee82ee,
    primary: #004e81,
  ),
  contrast: (
    info: #fff,
    dark: #fff,
    accent: #fff,
    primary: #fff,
    success: #fff,
    warning: #fff,
    default: #000,
    danger: #fff,
    theme: #fff,
    light: #000,
  ),
);

$base-font-size: cssVar(base-font-size);

$font-family: var(--app-font-family, meta.inspect(map-get($theme, font-family)));

$color: map-get($theme, types);
$cont: map-get($theme, contrast);

$bgColor: (
  default #fff cssVar(text),
  primary map-get($color, primary) #fff,
  success map-get($color, success) #fff,
  info map-get($color, info) #fff,
  warning map-get($color, warning) #fff,
  danger map-get($color, danger) #fff,
  theme map-get($theme, color) #fff,
  accent map-get($color, accent) #fff
);

$sideBarWidth: 225px;

// /* Available CSS vars */
// $shade000:#ffffff !default;    //surface
// $shade100:#f8f9fa !default;    //header background
// $shade200:#e9ecef !default;    //hover background
// $shade300:#dee2e6 !default;    //border, divider
// $shade400:#ced4da !default;    //input border
// $shade500:#adb5bd !default;    //input icon
// $shade600:#6c757d !default;    //text secondary color
// $shade700:#495057 !default;    //text color
// $shade800:#343a40 !default;    //unused
// $shade900:#212529 !default;    //unused
// $primaryColor: #2196F3 !default;
// $primaryLightColor: scale-color($primaryColor, $lightness: 60%) !default;
// $primaryDarkColor: scale-color($primaryColor, $lightness: -10%) !default;
// $primaryDarkerColor: scale-color($primaryColor, $lightness: -20%) !default;
// $primaryTextColor: #ffffff !default;

// $highlightBg: #E3F2FD !default;
// $highlightTextColor: #495057 !default;
// $panelContentPadding:1rem !default;
// $inlineSpacing:.5rem !default;
// $borderRadius:3px !default;

// --surface-a: #{$shade000};
// --surface-b: #{$shade100};
// --surface-c: #{$shade200};
// --surface-d: #{$shade300};
// --surface-e: #{$shade000};
// --surface-f: #{$shade000};
// --text-color: #{$shade700};
// --text-color-secondary: #{$shade600};
// --primary-color: #{$primaryColor};
// --primary-color-text: #{$primaryTextColor};
// --font-family: #{$font-family};
// --surface-0: #ffffff;
// --surface-50: #fafafa;
// --surface-100: #f5f5f5;
// --surface-200: #eeeeee;
// --surface-300: #e0e0e0;
// --surface-400: #bdbdbd;
// --surface-500: #9e9e9e;
// --surface-600: #757575;
// --surface-700: #616161;
// --surface-800: #424242;
// --surface-900: #212121;
// --gray-50: #fafafa;
// --gray-100: #f5f5f5;
// --gray-200: #eeeeee;
// --gray-300: #e0e0e0;
// --gray-400: #bdbdbd;
// --gray-500: #9e9e9e;
// --gray-600: #757575;
// --gray-700: #616161;
// --gray-800: #424242;
// --gray-900: #212121;
// --content-padding: #{$panelContentPadding};
// --inline-spacing: #{$inlineSpacing};
// --border-radius: #{$borderRadius};
// --surface-ground: #f8f9fa;
// --surface-section: #ffffff;
// --surface-card: #ffffff;
// --surface-overlay: #ffffff;
// --surface-border: #dee2e6;
// --surface-hover: #e9ecef;
