.t {
  &-c {
    text-align: center;
  }
}

.text-red11 {
  color: red !important;
}

.card {
  background: cssVar(mat-bg);
  // cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid cssVar(brdr);
  color: cssVar(color);
  flex-direction: column;

  &.homeCard {
    width: 20rem;
    height: 17rem;
    margin: 20px;
    cursor: pointer;

    &:hover {
      transition: all 0.1s ease-in;
      box-shadow: 0px 0px 15px 15px rgba(119, 118, 142, 0.209);
      border: 2px solid cssVar(brdr);
      background-image: radial-gradient(#262942, #030b44);
    }

    @media screen and (max-width: 991px) {
      margin: 15px;
    }

    img {
      max-width: 120px !important;
      max-height: 90px !important;
    }

    h2,
    .h2 {
      font-size: 18px;
      text-transform: uppercase;
      margin-bottom: 0px;
      text-align: center;
      display: flex;
      align-items: center;

      @media screen and (max-width: 991px) {
        font-size: 18px;
      }

      strong {
        font-size: 30px;
        padding-right: 3px;

        @media screen and (max-width: 991px) {
          font-size: 30px;
        }
      }

      & + h2,
      .h2 {
        margin-top: 0px;
        line-height: 30px;

        &.text-danger {
          color: cssVar(danger);
        }
      }
    }
  }

  &.grayCard {
    // background: cssVar(mat-bg-l);
    // align-items: flex-start;
    // padding-top: 7px;
    // border: none;
    background: var(--app-mat-bg-l, #222434);
    align-items: flex-start;
    padding-top: 0px;
    border: none;
    padding-bottom: 0px;

    .field {
      margin-bottom: 0px;
    }
  }
}

.p-datatable {
  .p-datatable-header {
    background: transparent !important;
    color: cssVar(color);
  }
}

.p-datatable-wrapper {
  background: cssVar(mat-bg);
  border-top: 1px solid cssVar(brdr);
  color: cssVar(color);

  .p-datatable-thead {
    tr {
      th {
        background: cssVar(mat-bg-l);
        color: #b8b9be;
        border-bottom: 1px solid cssVar(brdr);
        padding: 10px 2px !important;

        &:hover,
        &:active,
        &:focus {
          // background: cssVar(header-bg);
        }
      }
    }
  }

  .p-datatable-tbody {
    font-family: "Roboto", sans-serif;
    font-weight: 300;

    tr {
      background: transparent;
      font-size: 16px;

      &:nth-child(odd) {
        // background-color: cssVar(color);
        background-color: #eaf0f5;
      }

      &:nth-child(even) {
        background-color: cssVar(color);
      }

      td {
        border-bottom: 1px solid cssVar(brdr);
        padding: 5px 5px !important;
        color: cssVar(header-bg);
        font-size: 16px;
        font-family: "Roboto", sans-serif;
        // font-family:   "Roboto,Helvetica Neue,sans-serif";

        // font-family:"Roboto,Helvetica Neue,sans-serif";
        font-weight: 300;

        button {
          .p-button-label {
            font-size: 18px;
            font-family: "Roboto", sans-serif;
            font-weight: 300;
          }
        }
      }

      &.ui-state-highlight {
        background: #c8c9ca;

        &:hover {
          background: #d7ebff;
        }
      }
    }

    &.largeText {
      tr {
        td {
          font-size: 20px;

          button {
            .p-button-label {
              font-size: 20px;
            }
          }
        }
      }
    }
  }
  .text-success {
    color: #000000 !important;
  }
  .text-accent {
    color: #000000 !important;
  }
  .text-danger {
    color: #000000 !important;
  }
  .text-default {
    color: #000000 !important;
  }
}

.p-datatable {
  &.p-datatable-hoverable-rows {
    .p-datatable-tbody {
      tr:not(.p-highlight):hover {
        background: #b5b9be;
        color: cssVar(color);
        border: none;
        outline: none;
      }
    }
  }

  .p-sortable-column:not(.p-highlight) {
    &:hover,
    &:focus,
    &:active {
      background: cssVar(header-bg);
      color: cssVar(color);
    }
  }
}

.p-datatable {
  .p-sortable-column {
    &.p-highlight {
      background: cssVar(header-bg);
      color: cssVar(color);

      &:hover,
      &:focus,
      &:active {
        background: cssVar(header-bg);
        color: cssVar(color);
      }
    }
  }
}

.p-paginator {
  background: cssVar(mat-bg-l);
  color: cssVar(color);
  border: none;

  .p-paginator-current {
    color: cssVar(color);
    text-transform: lowercase !important;
  }
}

.btn {
  & + .btn {
    margin-left: 15px;
  }
}

.p-button {
  &.p-button-text {
    background-color: cssVar(light);
  }
}

.p-tabview {
  .p-tabview-nav {
    background: cssVar(header-bg);
    justify-content: center;
    border-color: cssVar(brdr);
    justify-content: flex-start;
    padding: 0px 15px;
    background-color: cssVar(mat-bg-l);

    @media screen and (max-width: 767px) {
      flex-wrap: wrap;
    }

    li {
      .p-tabview-nav-link {
        background: transparent;
        color: cssVar(info);
        border: none;
        margin: 2px;

        @media screen and (max-width: 767px) {
          font-size: 12px !important;
          padding: 10px 5px !important;
        }

        .p-ink {
          background: transparent;
        }

        &:hover,
        &:active,
        &:focus {
          outline: none;
          border: none;
          background: cssVar(mat-bg);
        }
      }

      &.p-highlight {
        .p-tabview-nav-link {
          background: cssVar(header-bg);
          box-shadow: none;
          color: cssVar(write);

          .p-ink {
            background: transparent;
            box-shadow: none;
          }

          .p-ink-active {
            box-shadow: none;
            outline: none;
            border: none;
          }

          &:hover,
          &:active,
          &:focus {
            outline: none;
            border: none;
            background: cssVar(header-bg);
            box-shadow: none;
          }
        }
      }
    }

    li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
      &:hover,
      &:active,
      &:focus {
        outline: none;
        border: none;
        background: cssVar(mat-bg-l);
        color: cssVar(write);
      }
    }

    li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
      outline: none;
      border: none;
      background: cssVar(mat-bg-l);
      color: cssVar(write);
    }
  }

  .p-tabview-panels {
    background-color: transparent !important;
    color: cssVar(write);
  }
}

.p-menu {
  &.p-menu-overlay {
    background-color: cssVar(mat-bg);
    color: cssVar(write);
    z-index: 9999;
  }

  .p-menuitem-link {
    .p-menuitem-text {
      color: cssVar(write);
    }
  }
}

.p-dialog {
  background-color: cssVar(mat-bg);
  color: cssVar(text-dialog);

  .p-dialog-header {
    background-color: cssVar(header-bg);
    color: cssVar(text-dialog);
  }

  .p-dialog-content {
    background: cssVar(mat-bg);
    color: cssVar(text-dialog);
  }

  .p-dialog-footer {
    background: cssVar(mat-bg);
    color: cssVar(text-dialog);
    button {
      margin: 5px;
    }
  }
}

.p-selectbutton {
  .p-button {
    background: cssVar(mat-bg);
    color: cssVar(text-dialog);
    border-color: cssVar(brdr);
  }
}

.table-responsive {
  max-width: 100%;
  margin: 15px;
  padding: 10px 0px 5px;
  background: cssVar(mat-bg-l);
  box-shadow: 0px 0px 5px 5px rgba(29, 29, 57, 0.309);
  border-radius: 10px;

  // border: 1px solid cssVar(brdr);
  color: cssVar(color);

  // overflow-y: auto;
  @media screen and (max-width: 991px) {
    margin: 10px 15px !important;
    padding: 10px !important;
  }

  @media screen and (max-width: 391px) {
    margin: 10px 5px !important;
    padding: 10px !important;
  }

  .p-datatable > .p-datatable-wrapper {
    overflow-y: unset !important;
    overflow-x: auto !important;
    text-transform: capitalize !important;

    @media screen and (max-width: 1510px) {
      overflow-x: auto !important;
    }
  }
}

.p-float-label {
  text-transform: capitalize !important;
}

// .p-datatable.p-datatable-striped .p-datatable-tbody>tr:nth-child(2n) {
//   background: transparent !important;
// }

.norecord {
  height: 56vh;
  text-align: center !important;
  font-size: 26px !important;
  background-color: #0e020200;
  // @media screen and (max-width: 3500px) {
  //   height: 56vh;
  // }
}
.nofound-popup {
  height: 50vh;
  text-align: center !important;
  font-size: 18px !important;
  background-color: #0e020200;
}

td {
  &.link {
    color: #101dea !important;
    // text-decoration: underline;
  }
}
.link-study {
  cursor: pointer;
  text-decoration: none;
  color: #414040;
  float: right;
}

td {
  a {
    color: #000000 !important;
    // text-decoration: underline;
  }
}

.p-datatable.p-datatable-sm .p-datatable-header {
  padding-top: 0px !important;
}

.searchx {
  height: 40px;
  max-height: 50px;
  margin-top: 3px;
  // margin-top: 6px !important;
  border-radius: 0px 5px 5px 0px !important;

  @media screen and (max-width: 1400px) {
    max-height: 36px;
  }
}

.bc {
  padding: 10px;
  margin-top: 10px;
}

.innerheader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;

  @media screen and (max-width: 991px) {
    flex-direction: column;
  }
}

.mobBtn {
  padding: 20px 15px 0px;

  .p-button-info,
  .p-button-outlined {
    margin-bottom: 3px;
    margin-top: 3px;
    max-height: 40px !important;
    padding-left: 7px;
    padding-right: 7px;
    font-size: 15px;
    color: #fff !important;

    @media screen and (max-width: 1400px) {
      padding-left: 5px;
      padding-right: 5px;
      font-size: 13px;
      max-height: 36px !important;
    }
  }

  .p-button-info {
    background-color: transparent !important;
    color: #0288d1 !important;
  }

  .daterange {
    padding-right: 0px !important;

    input {
      // color: #fff !important;
      border-color: #fff !important;
    }
  }
}

.active {
  .p-badge-danger {
    background-color: transparent !important;
    color: cssVar(danger) !important;
    cursor: pointer;
  }
}

.studyListDate {
  .p-float-label > label {
    left: 0.5rem;
    color: #e8edf2 !important;
    transition-duration: 0.2s;
  }
}
.daterange {
  &.p-inputwrapper-filled {
    input {
      background: transparent !important;
      color: #fff !important;
      border-color: #0288d1 !important;
    }
  }
}

.p-datepicker-trigger {
  background-color: transparent;
  border: 1px solid cssVar(brdr) !important;

  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
  }
}

.calinput {
  &.p-inputwrapper {
    input {
      border-color: #0288d1;

      @media screen and (max-width: 1400px) {
        font-size: 13px;
      }
    }

    ::placeholder {
      color: var(--app-brdr, #72709c);
    }

    :-ms-input-placeholder {
      // Internet Explorer 10-11 /
      color: #fff;
    }

    ::-ms-input-placeholder {
      //Microsoft Edge /
      color: #fff;
    }
  }

  .p-calendar {
    margin-top: 0px !important;
  }
}

.header {
  width: 100%;
}

.p-column-filter-row {
  .pi {
    color: #fff !important;
    padding-left: 10px;
  }
}

.butsty {
  width: 50px;
  height: 50px;
}

.formgrid {
  button {
    @media screen and (max-width: 600px) {
      margin-bottom: 10px;
    }
  }
}

.card {
  .grid {
    max-width: 100%;
  }
}

.p-multiselect .p-multiselect-label {
  padding: 0.7rem 0.5rem;
}

.dropdownlist {
  transform-origin: inherit;
  z-index: 99999;
  position: fixed;
  margin-top: -22px;
}

.viverPart {
  background: cssVar(mat-bg) !important;

  .head {
    text-align: center;
    color: cssVar(write);
  }

  .field {
    min-width: 33% !important;
  }

  .fwip {
    min-width: 100% !important;
  }

  .p-button {
    margin-bottom: 10px !important;
  }

  .p-autocomplete-dropdown {
    margin-bottom: 0px !important;
  }
}

.pi-exclamation-triangle {
  color: red;
}

.pi-filter-slash {
  color: rgb(253, 248, 248);
}

.p-splitter .p-splitter-gutter {
  background: black;
}

.login-password {
  .p-input-icon-right > .p-inputtext {
    padding-left: 25px !important;
    padding-right: 5px;
  }

  .p-icon {
    margin-left: 5px !important;
    margin-top: 3px;
  }
}

.p-column-filter-clear-button {
  color: #fff;
}

.p-datepicker .p-monthpicker .p-monthpicker-month {
  color: #fff;

  &:hover,
  &:active,
  &:focus {
    background: transparent;
  }
}

.p-yearpicker-year {
  color: #fff;
  padding: 5px;

  &:hover,
  &:active,
  &:focus {
    background-image: radial-gradient(#262942, #06091e);
  }
}

.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-highlight):not(.p-disabled):hover {
  background-image: radial-gradient(#262942, #06091e);
}

.button-padding {
  padding: 2px !important;
}

.p-multiselect-items-wrapper {
  max-height: 200px !important;
}

.p-dropdown-items-wrapper {
  max-height: 130px !important;
}

.graph-wrap {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  width: 100%;

  @media screen and (max-width: 991px) {
    flex-direction: column;
  }
}

.graph-child {
  width: 50%;

  @media screen and (max-width: 991px) {
    width: 100%;
  }
}

.studyDetails {
  .p-datatable > .p-datatable-wrapper {
    overflow: auto !important;
  }
}

.stdyinput {
  min-width: 200px;
}

.custom-multi-select {
  max-width: 300px;
  /* Adjust the value as needed */
}

.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td {
  border: 0.1px solid rgba(12, 0, 0, 0.1);
}

.mod-pos {
  .p-multiselect .p-multiselect-panel {
    position: fixed !important;
  }
}

.source-pos {
  .p-dropdown-panel {
    position: fixed !important;
  }
}

.source-pos1 {
  .p-autocomplete-panel {
    position: fixed;
    width: 31%;
  }
}

.btnclass {
  .pi-user-edit {
    font-size: 20px;
    color: #0288d1;
  }
}

.p-multiselect .p-multiselect-label {
  max-width: 100px !important;
}

.p-menu-overlay .ui-menu-list {
  transform-origin: center top 0px;
  top: 60px;
  left: 1731.15px;
  z-index: 9999;
}

.p-menu-overlay .ui-menuitem {
  transform-origin: center top 0px;
  top: 60px;
  left: 1731.15px;
  z-index: 9999;
}

.p-inputtextarea {
  resize: none;
}

.p-timepicker {
  color: aliceblue !important;
}

.sm-btn {
  @media screen and (max-width: 991px) {
    display: none;
  }
}

.dayss-filter {
  display: none;

  @media screen and (max-width: 991px) {
    display: block;
  }

  @media screen and (max-width: 460px) {
    max-width: 150px !important;
  }
}

.calender-filter {
  @media screen and (max-width: 991px) {
    margin-left: auto;
  }

  @media screen and (max-width: 460px) {
    max-width: 110px !important;
  }
}

.dayss-filter2 {
  display: none;

  @media screen and (max-width: 991px) {
    display: block;
  }

  @media screen and (max-width: 460px) {
    width: 100% !important;
    margin: 7px;
  }
}

.calender-filter2 {
  @media screen and (max-width: 460px) {
    max-width: 50% !important;
  }
}

.p-toolbar .head {
  @media screen and (max-width: 460px) {
    font-size: 15px !important;
    margin-left: 7px !important;
  }
}
.position-relative {
  position: relative;
}

.btn-tr {
  position: absolute;
  right: 0;
  top: 0;
  color: #fff;
  background: red;
  padding: 2px 5px;
  border-radius: 20px;
  line-height: 17px;
  width: 22px;
  height: 22px;
}

.stamp {
  position: relative;
  min-width: 110px img {
    max-width: 100px;
  }
}
.header_sidebtn {
  .p-button {
    background: transparent;
    border: none;
    padding-bottom: 0px;
    &:focus,
    &:active,
    &:hover {
      border: none;
      box-shadow: none;
      background: none;
    }
  }
  .p-button-icon,
  .pi,
  span {
    font-size: 27px;
  }
  p-tieredmenu {
    .p-tieredmenu-overlay {
      background-color: cssVar(mat-bg);
      color: cssVar(write);
      z-index: 9999;
      width: max-content;
    }
    .p-menuitem-link {
      .p-menuitem-text {
        color: cssVar(write);
        font-size: 16px;
        margin-right: 10px;
        @media screen and (max-width: 460px) {
          font-size: 14px;
        }
      }
      .p-menuitem-icon {
        width: 1.5rem;
        height: 1.5rem;
        padding-right: 5px;
        margin-right: 15px;
      }
    }
  }
}
// .closelabel{
//   .p-icon-wrapper{
//       display: none!important;
//     }
// }
.mc1 {
  margin-bottom: 25px;
  padding-bottom: 25px;
  // background: url("../assets/images/bg1.png");
  // background-size: cover;
  // background-repeat: no-repeat;
  // background-position: bottom center;

  // overflow: scroll;
  // @media screen and (min-width: 1040px) {
  //   height: calc(100vh - 100px);
  //   // margin-bottom: 125px;
  //   // padding-bottom: 125px;
  // }
}

.p-confirm-dialog-message {
  text-transform: initial !important;
}
.assig-class {
  .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
    padding-right: 1.5rem;
    min-width: 8rem !important;
  }
}

.ckeditor-help-dropdown {
  float: right !important;

  .p-inputtext {
    color: black !important;
    background: #ffffff !important;
    border-color: #aaa6a6 !important;
    padding: 4px !important;
    margin-top: 3px !important;
    font-style: italic !important;
  }

  .p-autocomplete .p-autocomplete-dropdown {
    background-color: #fff !important;
    border-color: #aaa6a6 !important;
    padding: 4px !important;
    margin-top: 3px !important;
    font-style: italic !important;
  }

  @media only screen and (max-width: 770px) {
    float: none !important;
  }
}

.norecordstudy {
  text-align: center !important;
  font-size: 26px !important;
  background-color: #0e020200;
  $base-height: var(--height-table); // Use the CSS variable
  $adjustment: 5vh; // Adjustment to be subtracted
  height: calc(#{$base-height} - #{$adjustment});
  overflow-y: hidden;
  overflow: unset;
}
.study-list-class {
  .p-datatable-wrapper {
    min-height: var(--height-table);
    // Add other styles if necessary
  }
}

.study-list-details {
  .p-datatable-wrapper {
    min-height: var(--height-table-study);
  }
}

.study-list-class1 {
  .p-datatable-wrapper {
    min-height: 35vh !important;
    height: 200px !important;
  }
}
.study-list-class2 {
  .p-datatable-wrapper {
    min-height: 48vh !important;
    height: 200px !important;
  }
}
.auto-chips {
  .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
    padding: 0.25rem 0.5rem;
    margin-right: 0.5rem;
    background: #e3f2fd;
    color: #495057;
    border-radius: 5px;

    border: 1px solid;
  }
}
// padding-top: 5px;
.pat-panel {
  .p-panel .p-panel-header {
    padding: 1rem;
    background: #222434;
    color: #e9edf1;
    border: black;
  }
  .p-panel-header .p-panel-header-icon {
    width: 2rem;
    height: 2rem;
    color: #e9edf1;
    border: 0 none;
    background: 0 0;
    border-radius: 50%;
    transition:
      background-color 0.2s,
      color 0.2s,
      box-shadow 0.2s;
  }
  .p-panel-content {
    padding: 1rem;
    border-top-color: rgb(72, 134, 195);
    background: #222434;
    color: #e9edf1;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
    border-top: 0 none;
    border: #222434;
  }
}
.table-responsive2 {
  max-width: 100%;
  margin: 20px 8px 0px;
  padding: 10px 0px 5px;
  background: var(--app-mat-bg-l, #222434);
  box-shadow: 0px 0px 5px 5px rgba(29, 29, 57, 0.309);
  border-radius: 10px;
  color: var(--app-color, #fff);
}
.chart-arrange{
  max-width: 400px;
}