@import "./scss/base/vars";
@import "./scss/base/mixins";
@import "./global.scss";

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100vh;
  // position: relative;
  overflow-x: hidden;
  background: cssVar(bg);
  background-color: #e6eff7;
  //  text-transform: capitalize;

  // @include font(cssVar($font-family));
}
.p-button-label {
  text-transform: capitalize !important;
}

.p-autocomplete {
  width: 100%;
}

.p-toolbar {
  // margin: 1em;
  // margin-top: 24px;
  // height: 40px;
  // padding: 0em;
  padding-bottom: 0em;
  background: none;
  border: none;

  .head {
    // margin: 1em;
    margin-top: 1rem;
    margin-left: 10px;
    font-size: 15px;
  }
}

.p-datatable > .p-datatable-wrapper {
  overflow: unset !important;
}

.p-datatable {
  .p-datatable-header {
    background: #fff;
    border: none;

    padding: 0px 6px 0px 6px;

    .tab-head {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .add-txt {
        width: 100%;
        font-size: 22px;
        font-weight: 600;
        border-radius: 20px;

        .p-button-label {
          font-size: 22px;
          font-weight: 600;
        }
      }
    }
  }

  .p-datatable-thead {
    tr {
      height: 30px;
      // th {
      // background-color: cssVar(table-head);
      // color: cssVar(light);
      // i {
      // color: cssVar(light) !important;
      // }
      // &:active,
      // &:hover,
      // &:focus {
      // background-color: cssVar(table-head) !important;
      // color: cssVar(light) !important;
      // i {
      // color: cssVar(light) !important;
      // }
      // }
      // }
    }
  }
}

// .p-tabview1 {
//   .p-tabview-panels {
//     background: transparent;
//     padding: 1rem;
//     border: 0 none;
//     // color: #495057;
//     border-bottom-right-radius: 2px;
//     border-bottom-left-radius: 2px;

//     @media screen and (min-width: 1400px) {
//       margin-top: 2px;
//     }
//   }

//   .p-tabview-nav {
//     background: transparent;

//     li {
//       .p-tabview-nav-link {
//         background: transparent;
//       }

//       &.p-highlight {
//         background: transparent;

//         .p-tabview-nav-link {
//           background: transparent;

//           &:focus {
//             box-shadow: none;
//           }
//         }
//       }
//     }
//   }
// }
.widthClass {
  display: flex;

  .p-calendar {
    width: 100%;
  }
}

.menuToggler {
  // padding: 6px;
  cursor: pointer;
  // border-radius: 50%;
  display: inline-block;
  border: 1px solid rgba(#{cssVar(dark, $color)}, 0.1);
  // width: 30px;
  // height: 30px;
  line-height: 0;
}

//commented due to design issue in calender view .If we provide padding here it will effect all the cards that we use
.card-list {
  margin: 1em;
  margin-bottom: 2em;
  // background-color: #fff;
  // padding: 1em;
  box-shadow:
    0 5px 7px -3px rgb(0 0 0 / 20%),
    0 11px 17px 2px rgb(0 0 0 / 7%),
    0 4px 20px 4px rgb(0 0 0 / 6%);
}

.card {
  margin: 1em;
  margin-bottom: -1em;
  padding: 1em;
  padding-top: 1em;
  box-shadow:
    0 5px 7px -3px rgb(0 0 0 / 20%),
    0 11px 17px 2px rgb(0 0 0 / 7%),
    0 4px 20px 4px rgb(0 0 0 / 6%);
}

.p-tabview .p-tabview-panels {
  padding: 0px;
  padding-top: 2px;
  background-color: map-get($theme, bg);
}

.inputarea {
  border-radius: 10px;
  width: 100%;
}

.p-inputtext {
  border-radius: 10px;
  width: 100%;
  //  text-transform: capitalize;
}

.p-password {
  border-radius: 10px;
  width: 100%;
}

.p-inputnumber {
  border-radius: 10px;
  width: 100%;
}

.p-dropdown {
  width: 100%;
  border-radius: 10px;
}

.p-multiselect,
.p-multiSelect {
  width: 100%;
  border-radius: 10px;
}

// .p-text-right {
//   display: flex;
//   justify-content: center;
//   .p-button-text {
//     padding-right: 20px;
//   }
// }
.p-inputgroup {
  width: 100%;
  border-radius: 10px;
}

.p-in .grid .field {
  // margin-bottom: 0;
  display: flex;
  flex-direction: column;
}

.field > label {
  // font-weight: 600;
  display: inline-block;
  margin-bottom: 0.2rem;
}

.p-button {
  margin-right: 0.5rem;
  border-radius: 6px;
}

.validation-star {
  font-size: 15px;
  color: red;
  line-height: 0;
}

.footer {
  bottom: 0;
  z-index: 1;
  width: 100%;
  padding: 5px 0px 0px 5px;
  margin-top: 15px;
  font-size: 8px;
  position: fixed;
  text-align: center;
  color: cssVar(footer-text);
  background-color: cssVar(footer-bg);
}

// @media only screen and (max-width: 680px) {
//   .footNote p {
//     display: none;
//   }

//   .footNote i {
//     font-size: 20px;
//     margin: 0 10px;
//   }
// }

.p-message {
  margin: 0.2em;

  .p-message-wrapper {
    padding: 0.7rem 1.5rem;
  }
}

.p-message.p-message-error {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9;
}

.p-messages {
  position: relative;
}

.p-message.p-message-error {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9;
}

.p-messages {
  position: relative;
}

.layout-wrapper {
  header.header {
    width: 100%;
    max-width: 100%;

    .menuToggler {
      .leftIcon {
        display: none;
      }

      .rightIcon {
        display: inline-block;
      }
    }
  }

  &.menuOpened {
    .header.header {
      // width: calc(100% - #{$sideBarWidth});
      .menuToggler {
        .leftIcon {
          display: inline-block;
        }

        .rightIcon {
          display: none;
        }
      }
    }
  }

  background: map-get($theme, bg);
}

.logo {
  top: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  padding: 4px;
  position: sticky;
  text-align: center;
  background: cssVar(header-bg);

  img {
    width: auto;
    max-height: 60px;
  }
}

.link {
  cursor: pointer;
  text-decoration: none;
  color: var(--app-link, #000000);
}
.payment {
  cursor: pointer;
  text-decoration: none;
  color: skyblue;
  padding-left: 0.25rem;
}
.bdy-part {
  text-transform: none;
}
