@import "./scss/base/theme";
@import "./scss/base/theme-dark";

@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&family=Roboto:ital,wght@0,500;0,700;0,900;1,400;1,500;1,700;1,900&display=swap");

:root {
  // --app-theme-name: "theme-dark";
  --app-write: #fff;
  --app-mat-bg: #181a2c;
  --app-mat-bg-l: #222434;
  --app-mat-bg-el: #252844;
  // --app-base-font-size: 14px;
  // --app-rad: 2px border-radius;
  --app-font-family: "Lato", sans-serif;
  --app-scfont-family: "Raleway", sans-serif;
  // --app-bg: #{rgba(#000, 0.5)};
  --app-text: #ffffff;
  --app-link: #ebebeb;
  // --app-brdr: #003558;
  --app-color: #ffffff;
  // --app-sidebar-bg: #{rgba(#004e81, 0.8)};
  // --app-sidebarText: #fff;
  // --app-login-text: #fff;
  // --app-login-bg: #003558;
  --app-footer-bg: #17192a;
  --app-footer-text: #fff;
  --app-header-bg: #17192a;
  --app-header-text: #ebebeb;
  --app-header-link: #ebebeb;
}