.footer {
  // @media screen and (max-width: 764px) {
  //   position: absolute !important;
  //   bottom: -50px !important;
  // }
  .p-link {
    @media screen and (max-width: 460px) {
      font-size: 10px;
    }
  }
}
.footerWraper {
  padding: 0px 30px;
}
