header.header {
  top: 0;
  right: 0;
  z-index: 999;
  height: 70px;
  padding: 1em;
  display: flex;
  position: fixed;
  align-items: center;
  color: cssVar(header-text);
  background: cssVar(header-bg);
  justify-content: space-between;
  @media screen and (max-width: 1200px) {
    height: 70px;
  }
  &:after {
    width: 100%;
    height: 2px;
    content: "";
    position: absolute;
    bottom: 0px;

    @include linear-gradient(to left, #a5a4bf, #2d2f3e);
    left: 0px;
    bottom: -2px;
  }
  .p-avatar {
    color: cssVar(header-text);
    background-color: cssVar(header-link);
  }

  // @media screen and (max-width: 991px) {
  //   // padding-left: 1;
  //   justify-content: flex-start;
  // }
  .loginuser {
    .p-component {
      border: 0px !important;
    }
  }
}
